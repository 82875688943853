import { Component, h } from "preact";
import Brochure from "../Components/Brochure";
import welcome from "../../images/welcome.jpg";
import map from "../../images/map.png";

export default class Home extends Component  {
    componentDidMount() {
        document.title = "Chase Point";
    }

    render() {
        return (
            <div class="page home test">
                <div class="container-sm p-0">
                    <img src={welcome} class="img-fluid" alt="A young girl and older woman sitting on a couch" width="1920" height="731" />
                </div>
                <div class="container-sm pt-2 content">
                    <div class="row">
                        <div class="col">
                            <article>
                                <h2 class="mt-2">Welcome to Chase Point</h2>

                                <p>Thank you for considering Chase Point as the location of your next home. You’ll find that life here is simply easier—from thoughtful design to maintenance-free lawns. In addition to our carefree living atmosphere, Chase Point offers something that not many other active adult communities offer: the freedom to customize your home to your unique taste. Explore more of what makes Chase Point the ideal place to call home.</p>
                            </article>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <h3>Easy Living</h3>
                                    <p>
                                        Located in beautiful Yorktown, Indiana, Chase Point is an active adult community perfect for those that
                                        are ready for laid-back living. Take a morning stroll on the neighborhood trails, host friends and family at
                                        the clubhouse facility, and rest easy knowing all outside maintenance is taken care of.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <h3>Make it Home</h3>
                                    <p>
                                        With two floorplans and a wide array of amenities to choose from, you can tailor your home to your needs
                                        and taste. All homes feature a covered back porch, 2-car garage, 3 bedrooms (including an oversized
                                        master suite), 2 full bathrooms, and a large laundry room.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <h3>Stay Active</h3>
                                    <p>
                                        Visit the on-site gym and recreation facility or take a short drive or walk to the Yorktown YMCA, swim in
                                        the community pool or find a pickle ball game with friends, Chase Point offers residents an opportunity to
                                        stay active. The neighborhood is also conveniently located near Muncie Elks Golf Club.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h2>Local Amenities</h2>
                            <p>Located in beautiful Yorktown, Chase Point is a short drive from local restaurants, walking trails, golf clubs, shops and entertainment in downtown Yorktown, and more.</p>
                            <a href={map} target="_BLANK"><img class="img-fluid img-thumbnail map" src={map} alt="Map of local amenities in Yorktown, Case Point, Indiana" width="663" height="885" /></a>
                        </div>
                        <div class="col">
                        <div style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.videodelivery.net/5102652596431e9a13bd472a4d468c43?preload=true&poster=https%3A%2F%2Fvideodelivery.net%2F5102652596431e9a13bd472a4d468c43%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <Brochure />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
