import { Component, h } from "preact";
import Brochure from "../Components/Brochure";
import floorplans from "../../images/Chase-Point-Plan-Update-2024.png";

import bonita from "../../images/bonita.jpg";
import naples from "../../images/naples.jpg";
import UpdateMetaDescription from "../functions";

export default class Elevation extends Component  {
    componentDidMount() {
        document.title = "Elevations | Chase Point";
        UpdateMetaDescription("Beautifully designed elevations and color scheme you’re proud to come home too. Choose one that best fits your needs and start your carefree living.");
    }

    render() {
        return (
            <div class="page elevation">
                <div class="container-sm p-0">
                    <img src={floorplans} class="img-fluid" alt="Floorplans" width="1919" height="1344" />
                </div>
                <div class="container-sm pt-2 content">
                    <div class="row">
                        <div class="col">
                            <article>
                                <h2 class="mt-2">Elevations</h2>

                                <p>Beautifully designed elevations and color scheme you’re proud to come home too. Choose one that best fits your needs and start your carefree living.</p>
                            </article>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <h3>Bonita</h3>
                            <div class="card mb-3">
                                <img src={bonita} class="card-img-top" alt="Bonita" width="648" height="432" />
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">3 bedrooms</li>
                                    <li class="list-group-item">2 baths</li>
                                    <li class="list-group-item">2,135 square feet of living space</li>
                                    <li class="list-group-item">677 square feet garage</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <h3>Naples</h3>
                            <div class="card mb-3">
                                <img src={naples} class="card-img-top" alt="Naples" width="648" height="432" />
                                    <ul class="list-group list-group-flush">
                                    <li class="list-group-item">3 bedrooms</li>
                                    <li class="list-group-item">2 baths</li>
                                    <li class="list-group-item">1,915 square feet of living space</li>
                                    <li class="list-group-item">544 square feet garage</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <Brochure />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
