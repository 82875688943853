import { Component, h } from "preact";
// import Brochure from "../Components/Brochure";
// import lifestyle from "../../images/lifestyle.jpg";
import UpdateMetaDescription from "../functions";

export default class Lifestyle extends Component  {
    componentDidMount() {
        document.title = "Lifestyle | Chase Point";
        UpdateMetaDescription("Located in beautiful Yorktown, Indiana, Chase Point is perfect for active adults who are ready for laid-back living.");
    }

    render() {
        return (
            <div class="page lifestyle">                
                <div class="container-sm pt-2 content">
                    <div class="page-content">
                        <style>
                            {`
                                #content {
                                    display: none;
                                    margin-top: 20px;
                                }
                                .form-content {
                                    margin: 0 auto;
                                    display: block;
                                }
                                .info-text {
                                    padding: 15px;
                                }
                                #theForm input {
                                    border: 1px solid #e4e4e4;
                                    padding: 5px;
                                    border-radius: 5px;
                                }
                                #theForm button {
                                    background: #497c8a;
                                    color: #fff;
                                    padding: 6px 10px;
                                    cursor: pointer;
                                    border-radius: 5px;
                                    border: none;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    transition: 0.3s;
                                }
                                #theForm button:hover {
                                    background: #198754;
                                    transition: 0.3s;
                                }
                            `}
                        </style>                       
                        <div class="form-content">
                            <div class="info-text">
                                <h1>**THIS FORM IS FOR CHASE POINT RESIDENTS USE ONLY **</h1>
                                <p><b>Enter the key number to view the form</b></p>
                                <form id="theForm">
                                    <label for="code">Enter Key Number:</label>
                                    <input type="text" id="code" name="code" />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>                       
                            <div id="content">
                                <p><b>Chase Point Residents – Please use this form to submit comments/issues regarding the Chase Point Community in general.</b></p>
                                <a href="https://www.cognitoforms.com/ChasePoint1/ReportMaintenanceIssues" target="blank">Report Maintenance Issues</a>
                                <div class="info" style="margin-top:35px;">
                                    <p><b> If you are having an issue with your home and need to submit a Warranty Request, please visit <a href="https://tkconstructors.com/warranty-service-request" target="blank">https://tkconstructors.com/warranty-service-request</a> and submit your request there.</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <script>
                        {`
                        document.getElementById('theForm').addEventListener('submit', function(event) {
                            event.preventDefault();
                            const enteredCode = document.getElementById('code').value;
                            const correctCode = '425400'; // Replace with your desired code

                            if (enteredCode === correctCode) {
                                document.getElementById('content').style.display = 'block';
                            } else {
                                alert('Incorrect code. Please try again.');
                            }
                        });
                        `}
                    </script>                 
                </div>
            </div>
        )
    }
}
