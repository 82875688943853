import { Component, h } from "preact";
import Router from "preact-router";
import Elevation from "../Pages/Elevation";
import Floorplans from "../Pages/Floorplans";
import Home from "../Pages/Home";
import Lifestyle from "../Pages/Lifestyle";
import Report from "../Pages/Report";
import Footer from "./Footer";
import Header from "./Header";
import Nav from "./Nav";

export default class App extends Component {
    render() {
        return (
            <div>
                <Header />
                <Nav />
                <div>
                    <Router>
                        <Home path="/" />
                        <Elevation path="/elevations" />
                        <Floorplans path="/floorplans" />
                        <Lifestyle path="/lifestyle" />
                        <Report path="/report" />
                    </Router>
                </div>
                <Footer />
            </div>
        )
    }
}
