import { Component, h } from "preact";
import Brochure from "../Components/Brochure";
import floorplans from "../../images/floorplans-2.png";
import UpdateMetaDescription from "../functions";

export default class Floorplans extends Component  {
    componentDidMount() {
        document.title = "Floor Plans | Chase Point";
        UpdateMetaDescription("With two floorplans and a wide array of amenities to choose from, you can tailor your home to your needs and taste.");
    }

    render() {
        return (
            <div class="page floorplans">
                <div class="container-sm p-0">
                    <img src={floorplans} class="img-fluid" alt="Floorplans" width="1919" height="650" />
                </div>
                <div class="container-sm pt-2 content">
                    <div class="row">
                        <div class="col">
                            <article>
                                <h2 class="mt-2">Floorplans</h2>

                                <p>
                                    With two floorplans and a wide array
                                    of amenities to choose from, you can
                                    tailor your home to your needs and
                                    taste. All homes feature a covered
                                    back porch, 2-car garage, 3 bedrooms
                                    (including an oversized master suite),
                                    2 full bathrooms, and a large laundry
                                    room. Make it home by choosing
                                    your cabinets, flooring, counter tops,
                                    bathroom configurations, and more.
                                </p>
                            </article>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <h3>Includes</h3>

                            <ul class="list-group">
                                <li class="list-group-item">Covered back porch</li>
                                <li class="list-group-item">2-car garage</li>
                                <li class="list-group-item">3 bedrooms, 2 full baths</li>
                                <li class="list-group-item">Large laundry room</li>
                                <li class="list-group-item">Oversized master suite</li>
                                <li class="list-group-item">Andersen windows</li>
                                <li class="list-group-item">Gas heat</li>
                                <li class="list-group-item">2-year service provided</li>
                            </ul>
                        </div>
                        <div class="col-12 col-sm-6">
                            <h3>You pick</h3>
                            <ul class="list-group">
                                <li class="list-group-item">Cabinets</li>
                                <li class="list-group-item">Flooring</li>
                                <li class="list-group-item">Counter tops</li>
                                <li class="list-group-item">Paint colors</li>
                                <li class="list-group-item">Bathroom configuration</li>
                                <li class="list-group-item">Kitchen Island</li>
                                <li class="list-group-item">And many more</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p>
                                <b>* See your salesperson for details</b>
                            </p>
                            <Brochure />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
