import { Component, h } from "preact";
import logo from "../../images/chase-point.svg";

export default class Header extends Component  {
    render() {
        return (
            <div class="container-sm header">
                <div class="row">
                    <div class="col">
                        <header class="d-flex flex-column flex-sm-row justify-content-sm-between p-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <img src={logo} alt="ChasePoint" class="logo" width="117" height="50" />
                            </div>
                            <div class="text-center text-md-end mt-3">
                                Sales Representative<br />
                                <a class="text-decoration-none text-success" href="mailto:ephillips@tkconstructors.com">Erin Phillips</a><br />
                                <a class="text-decoration-none text-success" href="tel:17657600792">765.760.0792</a>
                            </div>
                        </header>
                    </div>
                </div>
            </div>
        )
    }
}
