import { Component, h } from "preact";
import { Link } from "preact-router";

export default class Nav extends Component {
    render() {
        return (
            <div class="bd-nav container-sm">
                <div class="row">
                    <div class="col">
                        <nav>
                            <ul class="d-flex flex-column align-items-center flex-md-row justify-content-md-evenly">
                                <li>
                                    <Link href="/">Home</Link>
                                </li>
                                <li>
                                    <Link href="/elevations">Elevations</Link>
                                </li>
                                <li>
                                    <Link href="/floorplans">Floorplans</Link>
                                </li>
                                <li>
                                    <Link href="/lifestyle">Lifestyle</Link>
                                </li>
                                <li>
                                    <a target="_BLANK" href="https://docs.google.com/forms/d/e/1FAIpQLSekKtfQFgFPiSK5b-mIaCzn8XoPrUgI4R1_wWMujmZr3-ie3A/viewform?usp=sf_link">Contact Us <small><i class="fal fa-external-link"></i></small></a>
                                </li>                              
                                <li>
                                    <Link href="/report">Report Maintenance Issues</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}
