import { Component, h } from "preact";

export default class Footer extends Component {
    render() {
        return (
            <div class="bd-footer">
                <div class="container">
                    <div class="row">
                        <div class="col d-flex align-items-center justify-content-between">
                            <small>&copy; 2021 TK Constructors</small>
                            <div>
                                Chase Point<br />
                                9613 W. Premier Dr.<br />
                                Yorktown, Indiana 47396<br />
                                Phone: <a class="text-decoration-none text-success" href="tel:17654051019">765.405.1019</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
