import { Component, h } from "preact";
import Brochure from "../Components/Brochure";
import lifestyle from "../../images/lifestyle.jpg";
import UpdateMetaDescription from "../functions";

export default class Lifestyle extends Component  {
    componentDidMount() {
        document.title = "Lifestyle | Chase Point";
        UpdateMetaDescription("Located in beautiful Yorktown, Indiana, Chase Point is perfect for active adults who are ready for laid-back living.");
    }

    render() {
        return (
            <div class="page lifestyle">
                <div class="container-sm p-0">
                    <img src={lifestyle} class="img-fluid" alt="Adult men and women at a party" width="1920" height="731" />
                </div>
                <div class="container-sm pt-2 content">
                    <div class="row">
                        <div class="col">
                            <article>
                                <h2 class="mt-2">Lifestyle</h2>

                                <p>
                                    Located in beautiful Yorktown, Indiana, Chase Point is perfect for active adults who
                                    are ready for laid-back living. Take a morning stroll on the neighborhood trails, host
                                    friends and family at the clubhouse facility, or take advantage of the on-site gym,
                                    recreation facility, and pool.
                                </p>
                                <p>
                                    This sense of carefree living extends into the home, too. Residents can rest easy
                                    knowing all outside maintenance services are taken care of, including lawn mowing,
                                    landscaping, and snow removal. Upon request, residents can also enjoy inside
                                    maintenance services.
                                </p>

                            </article>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <h3>Services</h3>
                            <ul class="list-group">
                                <li class="list-group-item">Lawn maintenance</li>
                                <li class="list-group-item">Irrigation system</li>
                                <li class="list-group-item">Snow removal</li>
                                <li class="list-group-item">Landscaping maintenance</li>
                                <li class="list-group-item">Clubhouse</li>
                                <li class="list-group-item">Workout facility</li>
                                <li class="list-group-item">Pickleball courts and maintenance</li>
                            </ul>
                        </div>
                        <div class="col-12 col-sm-6">
                            <h3>Amenities</h3>
                            <ul class="list-group">
                                <li class="list-group-item">Pool</li>
                                <li class="list-group-item">Local trail system</li>
                                <li class="list-group-item">Clubhouse</li>
                                <li class="list-group-item">On-site gym and recreation facility</li>
                                <li class="list-group-item">Short drive to Yorktown YMCA &amp; shopping</li>
                                <li class="list-group-item">Close proximity to White River and Morrow’s Meadow trails</li>
                                <li class="list-group-item">Pickleball courts</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <Brochure />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
