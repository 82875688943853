import { Component, h } from "preact";

export default class Brochure extends Component {
    render() {
        return (
            <p>
                <a class="btn btn-primary" href="https://cdn.flipsnack.com/widget/v2/widget.html?hash=1p54pcxwb1" target="_BLANK">Download our free brochure</a>
            </p>
        )
    }
}
